<template>
  <div>
    <v-menu v-model="shower" :position-x="AreaX" :position-y="AreaY" offset-y transition="slide-y-transition">
      <v-list dense tile>
        <v-list-item
          v-for="(item, index) in ItemOption"
          :key="index"
          @click="fn_selectFunction(item.select_function)"
          :disabled="filedata.tag_status !== 'false'"
        >
          <v-list-item-icon>
            <v-icon medium v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="filedata.tag_status === 'false'" @click="fn_selectFunction('active')">
          <v-list-item-icon>
            <v-icon medium>check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <!-- //! active button -->
            <v-list-item-title>{{ $t("tagVersion.activebtn") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- <v-dialog v-model="opendialogrename" max-width="500px" persistent>
      <v-card>
        <v-layout justify-center row wrap>
          <v-flex lg10>
            <v-card-title>Rename</v-card-title>
            <v-text-field :label="filedata.file_name" v-model="renamevalue"></v-text-field>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="(renamevalue = ''), (opendialogrename = false)">cancle</v-btn>
              <v-btn @click="fn_rename_tag_version">submit</v-btn>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  computed: {
    ...mapState(['username', 'authorize','account_active','color','role_level']),
    ...mapState({processloader: 'loading'}),
    ...mapGetters(['dataUsername', 'dataAuthorize','dataAccountActive', 'dataAccesstoken', 'dataBusinessProfile','dataCitizenProfile', 'dataDepartmentAccessId','dataAccountId']),
    shower: {
      get() {
        console.log("show", this.show);
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closecurrentonly");
        }
      },
    },
  },
  props: ["show", "filedata", "AreaX", "AreaY", "parentfolder", "activefileID"],
  data() {
    return {
      check_aspect: false,
      listdatadowload:[],
      renamevalue: "",
      opendialogrename: false,
      ItemOption: [
        // {
        //   text: "rename",
        //   select_function: "rename",
        //   icon: "mdi-rename-box",
        // },
        {
          text: "tagVersion.deletebtn",
          select_function: "delete",
          icon: "delete",
        },
        {
          text: "tagVersion.download",
          select_function: "downlaod",
          icon: "mdi-download",
        },
      ],
    };
  },
  methods: {
    //! ตัวสับช่องทางไปแต่ละ function
    fn_selectFunction(option) {
      console.log("option", option);
      if (option === "rename") {
        this.opendialogrename = true;
      } else if (option === "delete") {
        this.check_fn_delete_tag_version();
      } else if (option === "active") {
        this.fn_activate_tag_version();
      } else if (option === "downlaod"){
        this.downloadfile(this.filedata);
      }
    },
    //! เลือกไฟล์ใน tag ให้เป็นตัวหลัก
    //! ตัวหลังจะเป็นไฟล์ที่แสดง
    async fn_activate_tag_version() {
      let payload = {
        file_id: this.filedata.file_id,
      };
      let auth = await gbfGenerate.generateToken();
      console.log("active payload", payload);
      this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "api/set_tag_active", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          console.log("activate res", response);
          if (response.data.status === "OK") {
            this.$emit("reload");
          } else if (response.data.status === "ER") {
            // console.log(response.data.errorMessage);
            Toast.fire({ icon: "error", title: response.data.errorMessage });
          }
        })
        .catch((e) => {
          console.log("update tag version error:", e);
          // Toast.fire({ icon: "error", title: this.$t("toast.cannotconnectonebox") });
        });
    },
    // เช็ค aspect ก่อน ลบเวอร์ชัน
    check_fn_delete_tag_version(){
      console.log("check_fn_delete_tag_version");
      this.check_get_smartbox();
    },
      // เช็คเพื่อดูว่ามีข้อมูล aspect ไหม
    async check_get_smartbox(){
      console.log("get_smartbox");
      let payload = {
        data_id: this.filedata.file_id,
        data_type: this.filedata.file_type == "folder" ? "folder" : "file"
      }
      console.log("payload",payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post( process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/smartbox/v1/get/info_files", payload,{ headers: { Authorization: auth.code } })
        .then((response) => {
          console.log("response",response);
          if (response.data.status == "OK") {
            console.log("dataAspect", response.data.data.dataAspect);
            if(response.data.data.templateName == ''){
              // ถ้าไม่มีข้อมูล aspect
              console.log("ถ้าไม่มีข้อมูล aspect");
              this.check_aspect = false;
            }else{
              // ถ้ามีข้อมูล aspect
              console.log("ถ้ามีข้อมูล aspect",this.filedata);
              this.check_aspect = true;
            }
            this.fn_delete_tag_version();
          } 
          else {
            Toast.fire({
              icon: "error",
              title: this.$t("ไม่สามารถเรียกข้อมูลได้"),
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    //! เอาไฟล์ออกมาจาก tag version
    async fn_delete_tag_version() {
      console.log("filedata", this.filedata);
      let auth = await gbfGenerate.generateToken();
      let payload = {
        file_id: this.filedata.file_id,
        tag_id: this.filedata.tag_id,
      };
      this.axios
        .post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + "/api/remove_file_from_tag_version", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          console.log("remove res", response);
          if (response.data.status === "OK") {
            // ถ้ามีข้อมูล aspect
            if(this.check_aspect === true){
            this.delete_tag_version_to_trash();
            }
            //? ให้โหลดดูไฟล์ใน tag version
            this.$emit("reload");
          } else if (response.data.status === "ER") {
            Toast.fire({ icon: "error", title: response.data.errorMessage });
          }
        })
        .catch((e) => {
          console.log("delete tag version error:", e);
          // Toast.fire({ icon: "error", title: this.$t("toast.cannotconnectonebox") });
        });
    },
    // ลบ tag_version ลงถังขยะ
    async delete_tag_version_to_trash() {
      let payload = {
        account_id: this.dataAccountId,
        user_id: this.dataUsername,
        data_id: this.filedata.file_id,
        data_type: this.filedata.data_type,
        // data_type: this.filedata.file_type === "folder" ? "folder" : "file",
        status_data: "TB",
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/remove_data_to_trash", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("delresponse", response);
            Toast.fire({
              icon: "success",
              title: this.$t("toast.text.remove") + this.filedata.file_name + " " + this.$t("toast.text.totrash"),
            });
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    },
     //dowload 1 file
     async downloadfile(currentfile) {
      this.listerror =[];
      this.$emit("closecurrentonly");
      console.log("currentfile", currentfile);
      // let checkfilesize = this.fn_calculate_size(currentfile.file_size);
      this.btsdownload = true;
      // console.log(currentfile);
      let datadowload = {};

      // console.log(this.listdatadowload);
      // check listdatadowload ว่าซ้ำมั้ย
      // let checkdata = this.listdatadowload.findIndex((v) => v.file_id === currentfile.file_id);
      // if (checkdata != -1) {
      //   Toast.fire({
      //     icon: "error",
      //     title: "พบการดาวน์โหลดซ้ำ กรุณาดาวน์โหลดใหม่อีกครั้ง",
      //   });
      //   // this.snackbardowload = false;
      //   this.opensnackbar = false;
      //   this.listdatadowload = [];
      this.newlist = [];
      // } else {
      this.listdatadowload.push(currentfile);

      // console.log(this.listdatadowload);

      for (let i = 0; i < this.listdatadowload.length; i++) {
        datadowload["fileid"] = this.listdatadowload[i].file_id;
        datadowload["name"] = this.listdatadowload[i].file_name;
        datadowload["value"] = 0;
        // console.log(dat adowload);
      }

      this.newlist.push(datadowload);
      // console.log(this.newlist);

      this.percentCompleted = 0;
      if (currentfile.data_type === "folder") {
        for (let i = 0; i < this.listdatadowload.length; i++) {
          this.dataIndex = this.newlist.findIndex((obj) => obj.name === this.listdatadowload[i].file_name);
        }
        //this.btsdownload = true;
        this.opensnackbar = true;
        this.new_list = this.newlist;
        let timeout = 4000;

        let all_file = [];
        let zip_name = "";
        let url_link;
        //test
        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
          "/api/request/download_folder?account_id=" +
          this.dataAccountId +
          "&folder_id=" +
          currentfile.file_id;

        //  let url =
        //   process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
        //   "/api/request/download_folder?account_id=" +
        //   "5af57356a1fb9db9638b87893ba2039a" +
        //   "&folder_id=" +
        //   "44464fbc77f6aaa0f4ebda1790243c2f";

        this.axios
          .get(url, {})
          .then((response) => {
            //console.log(response.data.status);
            if (response.data.status === "OK") {
              // all_file = response.data.all_file
              // zip_name =  response.data.zip_name
              // url_link = response.data.url
              //console.log("fff",url_link);
              //ฟิกtest
            //   response.data.all_file.push({
            //     file_id: "8ba3cc2dc065657e6b7c2a869c200000",
            //     full_path: "Mainfolder/TEST_MOVE_TEST/A2",
            //     location_file: "mapdrive/folder_downloaded/downloading/20b2030ebb3f774e1dce2a1b0561c68b20230517150000",
            //     path: "TEST_MOVE_TEST/A2"
            // })
              this.fn_loop_downloading(response.data.all_file, response.data.zip_name, response.data.url);
            } else {
              this.btsdownload = false;
              this.opensnackbar = false;
              Toast.fire({
                icon: "error",
                title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้",
              });
            }
          })
          .catch((error) => {
            this.btsdownload = false;
            this.opensnackbar = false;
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้",
            });
            console.log("err", error);
          });

        // for (let i = 0; i < this.listdatadowload.length; i++) {
        //   this.dataIndex = this.newlist.findIndex((obj) => obj.fileid === this.listdatadowload[i].file_id);
        // }
        // console.log("folder", this.dataIndex);

        // this.opensnackbar = true;
        // this.new_list = this.newlist;
        // let auth = await gbfGenerate.generateToken();
        // this.axios
        // .get(url, {
        //   headers: { Authorization: auth.code },
        //   onDownloadProgress: (progressEvent) => {
        //     // console.log("progressEvent", progressEvent);
        //     let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        //     if (this.newlist[this.dataIndex]["value"] === 95) {
        //       this.newlist[this.dataIndex]["value"] = 95;
        //     } else {
        //       this.newlist[this.dataIndex]["value"] = progresspercent;
        //     }
        //     // console.log("percenfolder", this.newlist[this.dataIndex]["value"]);
        //     this.newpercen = this.newlist[this.dataIndex]["value"];
        //     this.namefile = this.newlist[this.dataIndex]["name"];
        //   },
        //   withCredentials: false,
        //   responseType: "arraybuffer",
        // })
        // .then(response => {
        //   console.log("reponse folder",response);
        //   // ตรวจสอบว่า  API ทำงานถูกต้อง
        //   if (response.statusText === "OK") {
        //     // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
        //     if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
        //        // Backend ไม่ส่งไฟล์มา
        //       this.opensnackbar = false;
        //       let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
        //       Toast.fire({ icon: "error", title: res_error.errorMessage });
        //       } else {
        //         // Backend ส่งไฟล์มา
        //         this.newlist[this.dataIndex]["value"] = 100;
        //         this.btsdownload = false;
        //         let headers = response.headers;
        //         let blob = new Blob([response.data],{type:headers['content-type']});
        //         let link = document.createElement("a");
        //         link.href = window.URL.createObjectURL(blob);
        //         link.download = currentfile.file_name;
        //         link.click();
        //         setTimeout(() => {
        //           this.opensnackbar = false;
        //         }, 2500)
        //       }
        //   }else{
        //      this.opensnackbar = false;
        //   }
        //   this.listdatadowload = []; //เคลียร์listที่เลือกดาวน์โหลด
        //   this.newlist = []; //เคลียร์ array เป็น key แล้ว
        //   this.newpercen = []; //เคลียร์ค่าเปอร์เซ็น
        //   // console.log("ดาวน์โหลดเสร็จ",this.newlist);
        //   // console.log("ดาวน์โหลดเสร็จpercen",this.newpercen);
        // }).catch(error => {
        //   this.btsdownload = false;
        //   this.listdatadowload = []; //เคลียร์listที่เลือกดาวน์โหลด
        //   this.newlist = []; //เคลียร์ array เป็น key แล้ว
        //   this.newpercen = []; //เคลียร์ค่าเปอร์เซ็น
        //   Toast.fire({
        //     icon: "error",
        //     title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้",
        //   });
        //   console.log(error);
        // });
      } else {
        let payload = {
          account_id: this.dataAccountId,
          user_id: this.dataUsername,
          file_id: currentfile.file_id,
        };
        // let url =
        //   process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
        //   "/api/v2/download_file?user_id=" +
        //   this.dataUsername +
        //   "&file_id=" +
        //   currentfile.file_id;
         let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
          "/api/v1/download_file_s3?user_id=" +
          this.dataUsername +
          "&file_id=" +
          currentfile.file_id +
          "&account_id=" + this.dataAccountId

        for (let i = 0; i < this.listdatadowload.length; i++) {
          this.dataIndex = this.newlist.findIndex((obj) => obj.name === this.listdatadowload[i].file_name);
        }
        // console.log("file", this.dataIndex);

        // if (checkfilesize < 1.0) {
          console.log(currentfile);
        if(currentfile.status_encrypt === "Y"){      
          this.opensnackbar = true;
          this.new_list = this.newlist;
          let auth = await gbfGenerate.generateToken();
          console.log("น้อยกว่า 1 GB");
          this.axios
            .get(url, {
              headers: { Authorization: auth.code },
              onDownloadProgress: (progressEvent) => {
                // console.log("progressEvent", progressEvent);
                let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                // console.log(this.percentCompleted);

                if (this.newlist[this.dataIndex]["value"] === 95) {
                  this.newlist[this.dataIndex]["value"] = 95;
                } else {
                  this.newlist[this.dataIndex]["value"] = progresspercent;
                }
                // console.log("percenfile", this.newlist[this.dataIndex]["value"]);
                this.newpercen = this.newlist[this.dataIndex]["value"];
                this.namefile = this.newlist[this.dataIndex]["name"];
              },
              withCredentials: false,
              responseType: "arraybuffer",
            })
            .then((response) => {
              // ตรวจสอบว่า  API ทำงานถูกต้อง
              // if (response.statusText === "OK") {
              if (response.status === 200) {
                console.log("response++++++++++++ OK", response);
                try {
                  let type = currentfile.file_name.split(".");
                  type = type[type.length - 1].toLowerCase();
                  let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                  if (res_error.status === "ER" && type !== "json") {
                    this.opensnackbar = false;
                    Toast.fire({
                      icon: "error",
                      title: res_error.errorMessage,
                    });
                  } else {
                    this.opensnackbar = true;
                    this.newlist[this.dataIndex]["value"] = 100;
                    this.btsdownload = false;
                    let headers = response.headers;
                    let blob = new Blob([response.data], { type: headers["content-type"] });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = currentfile.file_name;
                    link.click();
                  }
                } catch (error) {
                  console.log("error", error);
                  this.opensnackbar = true;
                  this.newlist[this.dataIndex]["value"] = 100;
                  this.btsdownload = false;
                  let headers = response.headers;
                  let blob = new Blob([response.data], { type: headers["content-type"] });
                  let link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  link.download = currentfile.file_name;
                  link.click();
                }
                // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
                // if (
                //   parseInt(response.headers["content-length"]) < 700 &&
                //   parseInt(response.headers["content-type"]) === "application/json"
                // ) {
                //   // Backend ไม่ส่งไฟล์มา
                //   console.log("response < 1000", response);
                //   this.opensnackbar = false;
                //   let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                //   console.log("responseerror", res_error);
                //   Toast.fire({
                //     icon: "error",
                //     title: res_error.errorMessage,
                //   });
                //   // Backend ส่งไฟล์มา
                // } else {
                //   // console.log("response โหลดได้", response);
                //   // console.log(parseInt(response.headers["content-length"]));
                //   this.opensnackbar = true;
                //   this.newlist[this.dataIndex]["value"] = 100;
                //   this.btsdownload = false;

                //   let headers = response.headers;
                //   let blob = new Blob([response.data], { type: headers["content-type"] });
                //   let link = document.createElement("a");
                //   link.href = window.URL.createObjectURL(blob);
                //   link.download = currentfile.file_name;
                //   link.click();
                //   // const blob = new Blob([response.data]);
                //   // const content = response.headers["content-type"];
                //   // saveAs(blob, currentfile.file_name);
                //   setTimeout(() => {
                //     this.opensnackbar = false;
                //   }, 2500);
                // }
              } else {
                Toast.fire({
                  icon: "error",
                  title: this.$t("toast.cannotconnectonebox"),
                });
              }
              setTimeout(() => {
                this.opensnackbar = false;
              }, 2500);
              // this.opensnackbar = false;
              this.listdatadowload = []; //เคลียร์listที่เลือกดาวน์โหลด
              this.newlist = []; //เคลียร์ array เป็น key แล้ว
              this.newpercen = []; //เคลียร์ค่าเปอร์เซ็น
              // console.log("ดาวน์โหลดเสร็จfile",this.newlist);
              // console.log("ดาวน์โหลดเสร็จpercenfile",this.newpercen);
            })
            .catch((error) => {
              this.btsdownload = false;
              this.opensnackbar = false;
              Toast.fire({
                icon: "error",
                title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้",
              });
              console.log("err", error);
            });
          }else{
            console.log("เข้า status_encrypt = N");
            this.axios.get(url)
            .then(response => {
              console.log("=",response);
              // แก้เพราะดาวน์โหลดละ error
              if(response.data.url === undefined){
                window.location.href = response.config.url;
              }else{
                window.location.href = response.data.url;
              }
            }).catch(console.error)

          }
        // } else {
        //   this.btsdownload = true;
        //   this.opensnackbardownloadfile = true;
        //   let timeout = checkfilesize * 4000;
        //   window.location.assign(url);
        //   setTimeout(() => {
        //     this.opensnackbardownloadfile = false;
        //   }, timeout);
        // }
      }
      // }
    },
    // fn_rename_tag_version() {
    //   let payload = {
    //     file_id: this.filedata.file_id,
    //     folder_id: this.parentfolder,
    //     // folder_id: this.filedata.folder_id,
    //     new_name: this.renamevalue,
    //     old_name: this.filedata.file_name,
    //   };
    //   this.axios
    //     .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/tag_version_rename", payload)
    //     .then((response) => {
    //       console.log("rename res", response);
    //       if (response.data.status === "OK") {
    //         // ให้โหลดดูไฟล์ใน tag version
    //         this.$emit("reload");
    //         this.opendialogrename = false;
    //       }
    //     })
    //     .catch((e) => {
    //       console.log("rename tag version error:", e);
    //     });
    // },
  },
};
</script>

<style></style>
